import { useEffect, useState } from "react";
import { EventDataType, OrganizerDisplayType } from "data/types";
import { auth, fs} from "./../firebaseSetup"
import { getAuth, createUserWithEmailAndPassword, signInAnonymously } from "@firebase/auth";

/**
 * Hook that load event data
 */
function useHomePageDataLoader(
    {
        isLoading,
        initEvents,
        newEvents,
        tabActive,
        tabs,
      }: {
        isLoading: boolean;
        initEvents: EventDataType[];
        newEvents?: EventDataType[];
        tabs: string[];
        tabActive: string;
      }) {
    let timeOut: NodeJS.Timeout | null = null;
    const [currentEvents, setCurrentEvents] = useState<EventDataType[]>(initEvents);
    const [currentOrganizers, setCurrentOrganizers] = useState<OrganizerDisplayType[]>();

    useEffect(() => {
        if (isLoading) {
            return;
          }
          //login to firebase
          let userUID = ""
          if (auth.currentUser != null){
            userUID = auth.currentUser.uid
          }
        fetch(`https://us-central1-blink-574af.cloudfunctions.net/v1EventsAPIFetchHomepage?user_id=${userUID}&count=${10}`,{
         headers: {
           "Content-Type": "application/json",
         },
  
       }).then((response) => {
         const responseTask = response.json();
         responseTask.then((data) => {
           let dataItm = {event: {data: [], count: 0}, organizer: {data: [], count: 0}}
           dataItm = data
           let newEventData: EventDataType[] = []
           let newOrganizerData: OrganizerDisplayType[] = []
           dataItm.event.data.forEach((remoteItm: any) => {
               let eId = ""
               let eTitle = ""
               let eDescription = ""
               let ePhotoURL = ""
               let ePhotoURLs: string[] = []
               let eAddress = ""
               let eStartDateString = ""
               let eEndDateString = ""
               let ePrice = 0
              
               if (remoteItm.id != null){
                   eId = remoteItm.id
               }
               if (remoteItm.title != null){
                   eTitle = remoteItm.title
               }
               if (remoteItm.description != null){
                   eDescription = remoteItm.description
               }
               if (remoteItm.startDateString != null){
                   eStartDateString = remoteItm.startDateString
               }
               if (remoteItm.endDateString != null){
                   eEndDateString = remoteItm.endDateString
               }
               if (remoteItm.photoURL != null){
                   ePhotoURL = remoteItm.photoURL
               }
               if (remoteItm.photoURLs != null){
                   ePhotoURLs = remoteItm.photoURLs
                   if (ePhotoURLs.length == 0){
                     ePhotoURLs = [ePhotoURL]
                   }
               }
               else{
                   ePhotoURLs = [ePhotoURL]
               }
               if (remoteItm.price != null){
                   ePrice = remoteItm.price
               }
               if (remoteItm.address != null){
                   if (remoteItm.address.fullAddress != null){
                       eAddress = remoteItm.address.fullAddress
                   }
               }
               let eventDataItem: EventDataType = {
                 id: eId,
                 title: eTitle,
                 description: eDescription,
                 startDateString: eStartDateString,
                 endDateString: eEndDateString,
                 photoURLs: ePhotoURLs,
                 photoURL: ePhotoURL,
                 location: remoteItm.location,
                 viewCount: 0,
                 address: eAddress,
                 price: ePrice,
               }
               newEventData.push(eventDataItem)
           })
           setCurrentEvents(newEventData)

           dataItm.organizer.data.forEach((remoteItem: any) => {
            let organizerItem: OrganizerDisplayType = {
                id: remoteItem.id,
                name: remoteItem.name,
                description: remoteItem.description,
                profileURL: remoteItem.profileURL,
                location: remoteItem.location,
                country: remoteItem.country,
                event_count: remoteItem.event_count,
                member_count: remoteItem.member_count,
                info: {
                    firstName: remoteItem.info.firstName,
                    lastName: remoteItem.info.lastName,
                    phone: remoteItem.info.phone,
                    work: remoteItem.info.work,
                    name: remoteItem.info.name,
                    wechat: remoteItem.info.wechat,
                    email: remoteItem.info.email
                }
              }
              newOrganizerData.push(organizerItem)
           })
           setCurrentOrganizers(newOrganizerData)

         })
       })
          return () => {
            timeOut && clearTimeout(timeOut);
          };
    
  }, [isLoading]);

  return {events: currentEvents, organizers: currentOrganizers};
}

export default useHomePageDataLoader;
