import React, { FC } from "react";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import DatePicker from "react-datepicker";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";



export interface PageCreateEventProps {}

const PageCreateEvent04: FC<PageCreateEventProps> = () => {

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );

  return (
    <CommonLayout
      index="04"
      backtHref="/create-event-03"
      nextHref="/create-event-05"
    >
      <>
        <h2 className="text-2xl font-semibold">Forms and FAQs</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem
            label=""
            desc="Choose a name for your wonder event. Event with short and procise name will generate 30% more views"
          >
              <ButtonSecondary onClick={toggleDrawer(true)}>
                  <span className="ml-3">Add form question</span>
              </ButtonSecondary>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
          </FormItem>

        </div>


      </>
    </CommonLayout>
  );
};

export default PageCreateEvent04;
