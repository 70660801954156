
  import { User, signOut, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
  import { useNavigate } from "react-router-dom";
  import { auth, SignOutUser, userStateListener } from "../firebaseSetup";
  import { createContext, useState, useEffect, ReactNode } from "react";
  

  interface Props {
    children?: ReactNode
  }

  

  export const AuthContext = createContext({
    // "User" comes from firebase auth-public.d.ts
    currentUser: {} as User | null,
    setCurrentUser: (_user:User) => {},
    signUpNewUser: (email:string, password: string) => {},
    login: (email:string,password:string) => {},
    signOut: () => {}
  });

  export const AuthProvider = ({ children }: Props) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null)
    //const navigate = useNavigate()

    useEffect(() => {
      const unsubscribe = userStateListener((user) => {
        if (user) {
          setCurrentUser(user)
        }
      });
      return unsubscribe
    }, [setCurrentUser]);

    // As soon as setting the current user to null, 
    // the user will be redirected to the home page. 
    const signOut = () => {
      SignOutUser()
      setCurrentUser(null)
      localStorage.removeItem('user_uid')
      localStorage.removeItem('org_id')
      //navigate('/')
    }

    const signUpNewUser = (email:string, password:string) => {
      return createUserWithEmailAndPassword(auth, email, password)
    }
  
    const login = (email:string, password: string) => {
      return signInWithEmailAndPassword(auth,email,password)
    }
  

    const value = {
      currentUser, 
      setCurrentUser,
      signUpNewUser,
      login,
      signOut
    }


    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
  }
  