import React, { FC } from "react";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import DatePicker from "react-datepicker";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";

export interface PageCreateEventProps {}

const PageCreateEvent05: FC<PageCreateEventProps> = () => {
  return (
    <CommonLayout
      index="05"
      backtHref="/create-event-04"
      nextHref="/create-event-preview"
    >
      <>
        <h2 className="text-2xl font-semibold">Event Settings</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
         
          <FormItem
            label="Add agenda"
            desc="Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included."
          >
            <Select>
              <option value="no">No Agenda</option>
              <option value="yes">Create Agenda</option>
            </Select>
          </FormItem>

          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

          <FormItem
            label="Request for sponsors?"
            desc="Event Go offer a great varity of sponsors in our network"
          >
            <Select>
              <option value="no">No Sponsor</option>
              <option value="yes">Create Sponsor Request</option>
            </Select>
          </FormItem>

        </div>


      </>
    </CommonLayout>
  );
};

export default PageCreateEvent05;
