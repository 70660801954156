import Label from "./../../components/Label/Label";
import React, { FC } from "react";
import Avatar from "./../../shared/Avatar/Avatar";
import ButtonPrimary from "./../../shared/Button/ButtonPrimary";
import Input from "./../../shared/Input/Input";
import Select from "./../../shared/Select/Select";
import Textarea from "./../../shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";
import Grid from '@mui/material/Grid2';
import { DataGrid } from "@mui/x-data-grid";
import { Box, Paper, Button, IconButton, Typography, useTheme } from "@mui/material";
import { useParams } from "react-router";

export interface AccountPageProps {
  className?: string;
}

const EventCustomers: FC<AccountPageProps> = ({ className = "" }) => {

  const {id} = useParams()

  const dashboardsArray = [
    {
      id: '',
      title: 'Total Sales',
      increase: 0,
      total: 0,
      time: 'Today'
    },
    {
      id: '',
      title: 'Total Members',
      increase: 0,
      total: 0,
      time: 'Today'
    },
    {
      id: '',
      title: 'Total Emails',
      increase: 0,
      total: 0,
      time: 'Today'
    },
    {
      id: '',
      title: 'Total Orders',
      increase: 0,
      total: 0,
      time: 'Today'
    },
    {
      id: '',
      title: 'Total Tickets',
      increase: 0,
      total: 0,
      time: 'Today'
    },
    {
      id: '',
      title: 'Total Impressions',
      increase: 0,
      total: 0,
      time: 'Today'
    }
  ]

  const customerColumn = [
    { field: "id", flex: 1, headerName: "Email" },
    { field: "firstName", flex: 1, headerName: "First Name" },
    { field: "lastName", flex: 1, headerName: "Last Name" },
    { field: "tickets", flex: 1, headerName: "Tickets" },
    { field: "timestamp", flex: 1, headerName: "Timestamp" },
  ]

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event Organizer Dashboard</title>
      </Helmet>
      <CommonLayout eventId={id || ""} eventTitle="">
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <div className="flex justify-between items-center">
            <h2 className="text-3xl font-semibold">Attendees (0)</h2>
            <ButtonPrimary>New Attendee</ButtonPrimary>
          </div>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <DataGrid 
        checkboxSelection 
        sx={{ overflowX: 'scroll' }}
        rows={[]} 
        columns={customerColumn} />
        </div>
      </CommonLayout>
    </div>
  );
};

export default EventCustomers;
