import React, { FC } from "react";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { MapPinIcon } from "@heroicons/react/24/solid";
import { Map, Marker } from "@vis.gl/react-google-maps";
import Label from "components/Label/Label";
import DatePicker from "react-datepicker";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";

export interface PageCreateEventProps {}

const PageCreateEvent01: FC<PageCreateEventProps> = () => {
  return (
    <CommonLayout
      index="01"
      backtHref="/create-event-01"
      nextHref="/create-event-02"
    >
      <>
        <h2 className="text-2xl font-semibold">Enter Basic Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}

          <FormItem
            label="Event Name"
            desc="Choose a name for your wonder event. Event with short and procise name will generate 30% more views"
          >
            <Input placeholder="Sunday morning running meetup" />
          </FormItem>

          <FormItem
            label="Event Description"
            desc="Summarize your event, you can write a short description for your event."
          >
            <Textarea placeholder="Short but procise" rows={6} />
          </FormItem>
         
          <FormItem
            label="Choose Event Types"
            desc="Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included."
          >
            <Select>
              <option value="public">Public Event - Everyone can register</option>
              <option value="private">Private Event - Only person with links</option>
            </Select>
          </FormItem>

          <FormItem
            label="Event Start Date"
            desc="Choose a name for your wonder event. Event with short and procise name will generate 30% more views"
          >
            <Input type="datetime-local" />
          </FormItem>

          <FormItem
            label="Event End Date"
            desc="Choose a name for your wonder event. Event with short and procise name will generate 30% more views"
          >
            <Input type="datetime-local" min={"2024-09-17"} />
          </FormItem>

        </div>

        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        </div>

        <div className="space-y-8">
          <ButtonSecondary>
            <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
            <span className="ml-3">Use current location</span>
          </ButtonSecondary>

          <FormItem label="Country/Region">
            <Select>
              <option value="canada">Canada</option>
              <option value="usa">United States</option>
              <option value="china">China</option>
            </Select>
          </FormItem>
          <FormItem label="Street">
            <Input placeholder="" />
          </FormItem>
          <FormItem label="Unit #">
            <Input placeholder="" />
          </FormItem>
          <FormItem label="City">
            <Input placeholder="" />
          </FormItem>
          <FormItem label="State/Province">
            <Input placeholder="" />
          </FormItem>
          <FormItem label="Postal code / Zip code">
            <Input placeholder="" />
          </FormItem>
          <FormItem label="Place name">
            <Input placeholder="" />
          </FormItem>

          <div>
            <Label>Detailed address</Label>
            <span className="block mt-1 text-sm text-neutral-500 dark:text-neutral-400">
              1110 Pennsylvania Avenue NW, Washington, DC 20230
            </span>
            <div className="mt-4">
              <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
              <div className="rounded-xl overflow-hidden">
                  <Map
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    defaultZoom={15}
                    defaultCenter={{
                      lat: 55.9607277,
                      lng: 36.2172614,
                    }}
                    gestureHandling={"greedy"}
                  >
                    <Marker
                      position={{ lat: 55.9607277, lng: 36.2172614 }}
                      draggable
                      onDragEnd={(e) => console.log(e)}
                    />
                  </Map>
                </div>
            </div>
                </div>
                </div>
        </div>


        <div>
          <h2 className="text-2xl font-semibold">Cover Image</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        </div>
        <div>
            <div className="mt-5 ">
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-neutral-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                    >
                      <span>Upload a file</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-neutral-500 dark:text-neutral-400">
                    PNG, JPG, GIF up to 10MB
                  </p>
                </div>
              </div>
            </div>
          </div>


      </>
    </CommonLayout>
  );
};

export default PageCreateEvent01;
