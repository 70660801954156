import React, { FC } from "react";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

export interface PageCreateEventProps {}

const PageCreateEvent02: FC<PageCreateEventProps> = () => {
  return (
    <CommonLayout
      index="02"
      backtHref="/create-event-01"
      nextHref="/create-event-03"
    >
      <>
        <h2 className="text-2xl font-semibold">Tickets and Coupons Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}

          <FormItem
            label="Total attendees"
            desc="Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included."
          >
            <Select>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="250">250</option>
              <option value="500">500</option>
              <option value="750">750</option>
              <option value="1000">1000</option>
              <option value="custom">custom</option>
            </Select>
          </FormItem>


          <FormItem
            label="Choose Pricing Types"
            desc="Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included."
          >
            <Select>
              <option value="free">Free Ticket - Free Admission</option>
              <option value="paid">Paid Ticket - Add your pricing</option>
            </Select>
            <ButtonSecondary className="mt-3" onClick={()=>{}}>
                <span className="ml-3">Add Ticket Type</span>
            </ButtonSecondary>
          </FormItem>

        </div>


      </>
    </CommonLayout>
  );
};

export default PageCreateEvent02;
