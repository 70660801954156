import { Tab } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { FC, Fragment, useEffect, useState } from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import { GuestsObject } from "components/HeroSearchForm/type";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from "components/ModalSelectGuests";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import GuestsInput from "./../../components/HeroSearchForm2Mobile/GuestsInput";
import { EventFormQuestionType, EventPriceDataType } from "data/types";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useParams } from "react-router-dom";
import Select from "shared/Select/Select";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Alert from '@mui/material/Alert';


export interface CheckOutPagePageMainProps {
  className?: string;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = "",
}) => {

  const {id, viewId} = useParams()

  const [eventPrices, setEventPrices] = useState<EventPriceDataType[]>([])
  const [orderPrices, setOrderPrices] = useState<EventPriceDataType[]>([])
  const [orders, setOrders] = useState<{[key: string]: EventPriceDataType}>({})
  const [orderTotal, setOrderTotal] = useState<number>(0)

  let [eventDetails, setEventDetails] = useState({
    title: "",
    description: "",
    photoURL: "",
    location: "",
    startDateString: "",
    endDateString: "",
    options: {},
    address: {},
  })
  let [formQuestions, setFormQuestions] = useState<EventFormQuestionType[]>([])
  let [success, setSuccess] = useState<string>("")

  useEffect(()=>{
    
    fetch("https://us-central1-blink-574af.cloudfunctions.net/v1EventsAPIGetTicketData?event_id="+(id || "")+"&view_id="+(viewId || ""),{
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      const responseTask = response.json();
      responseTask.then((data) => {
        setOrderTotal(0)
        if (data.event != null){
          setEventDetails(data.event)
        }
        if (data.form != null){
          if (data.form.data != null){
            setFormQuestions(data.form.data)
          }
        }
        if (data.tickets != null){
          let pricesArray: EventPriceDataType[] = []
          pricesArray = Object.values(data.tickets)
          pricesArray = pricesArray.filter((priceItm)=>{
            let endSeconds = priceItm.saleEnd._seconds             
            let endD = new Date(0)
            endD.setUTCSeconds(endSeconds)
            let today = new Date()
            if (endD > today){
              return true
            }
            else{
              return false
            }
          })
          pricesArray.sort((a,b)=>{
            return a.order - b.order
          })
          setEventPrices(pricesArray)
        }
      })
    })
  },[])

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <img
                alt=""
                className="absolute inset-0 object-cover"
                sizes="200px"
                src={eventDetails.photoURL}
              />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {eventDetails.location}
              </span>
              <span className="text-base font-medium mt-1 block">
                {eventDetails.title}
              </span>
            </div>
            <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
              {eventDetails.startDateString}
            </span>
            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Order Summary</h3>
          {
            Object.values(orders).filter((orderItm) => {
              return orderItm.quantity > 0
            }).map((orderItm) => {
              return <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>{orderItm.priceDescription} X{orderItm.quantity}</span>
              <span>{orderItm.price.toLocaleString('en-US',{style: 'currency', currency: orderItm.priceCurrency})}</span>
            </div>
            })
          }
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>{orderTotal.toLocaleString("en-US",{style: 'currency', currency: 'CAD'})}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirm and payment
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h3 className="text-2xl font-semibold">Your Event</h3>
            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline  mt-1 cursor-pointer"
                >
                  View event details
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle="Event details"
            />
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
            <ModalSelectDate
              renderChildren={({ openModal }) => (
                <button
                  className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                  type="button"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Date</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      {eventDetails.startDateString}
                    </span>
                  </div>
                  <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" />
                </button>
              )}
            />

            <ModalSelectGuests
              renderChildren={({ openModal }) => (
                <button
                  type="button"
                  className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Tickets</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      <span className="line-clamp-1">
                        Tickets
                      </span>
                    </span>
                  </div>
                  <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" />
                </button>
              )}
            />

          </div>
        </div>

        <div>
        <h3 className="text-2xl font-semibold">Select Tickets</h3>
        {success && <Alert severity="success">
          {success}
        </Alert>}
        { eventPrices?.map((priceItm) => {
          return <NcInputNumber
          className="w-full mt-6"
          defaultValue={0}
          onChange={(value) => {
            let orderItems = orders
            if (Object.keys(orderItems).includes(priceItm.id)){
              let orderD = orderItems[priceItm.id]
              orderD.quantity = value
              orderItems[priceItm.id] = orderD
            }
            else{
              let currentItm = priceItm
              currentItm.quantity = value
              orderItems[priceItm.id] = currentItm
            }
            let orderT = 0
            Object.values(orderItems).forEach((itmVal) => {
              orderT += itmVal.price * itmVal.quantity
            })
            setOrders(orderItems)
            setOrderTotal(orderT)
            setSuccess(`${priceItm.priceDescription} X ${priceItm.quantity} added to cart`)
          }}
          max={priceItm.max}
          min={priceItm.min}
          label={priceItm.price.toLocaleString('en-US',{style: 'currency', currency: priceItm.priceCurrency})}
          desc={priceItm.priceDescription}
        />
        })}
          
          </div>
          

        <div>
          
          <h3 className="text-2xl font-semibold">Information</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

          <div className="mt-6">
            
            <Tab.Group>
              <Tab.List className="flex my-5 gap-1">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      Registration
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5  rounded-full flex items-center justify-center focus:outline-none  ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : " text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      <span className="mr-2.5">FAQ</span>
                      
                    </button>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel className="space-y-5">
                <div className="flex space-x-5  ">
                    <div className="flex-1 space-y-1">
                      <Label>First Name *</Label>
                      <Input type="text" />
                    </div>
                    <div className="flex-1 space-y-1">
                      <Label>Last Name *</Label>
                      <Input />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <Label>Email Address *</Label>
                    <Input type="email" defaultValue="" />
                  </div>
                  <div className="space-y-1">
                    <Label>Confirm Email Address *</Label>
                    <Input type="email" defaultValue="" />
                  </div>
                  <div className="space-y-1">
                    <h3 className="text-1xl font-semibold">Additional Questions</h3>
                  </div>
                  {
                    formQuestions.map((ques) => {
                      if (ques.optionType == "options"){
                        //select
                        return <div className="space-y-1">
                          <Label>{ques.order+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <Select>
                            {
                              ques.options.map((itm)=>{
                                return <option value={itm}>{itm}</option>
                              })
                            }
                          </Select>
                          </div>
                      }
                      else if (ques.optionType == "text"){
                        if (ques.valueType == "phone"){
                          return <div className="space-y-1">
                          <Label>{ques.order+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <PhoneInput
                                  placeholder={ques.title}
                                  value={""}
                                  defaultCountry="CA"
                                  onChange={(e) => {
                                    
                                  }}/>
                          </div>
                        }
                        else{
                          return <div className="space-y-1">
                          <Label>{ques.order+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <Input type={'text'} defaultValue="" />
                          </div>
                        }
                        
                      }
                      else if (ques.optionType == "file"){
                        return <div className="space-y-1">
                          <Label>{ques.order+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <Input type="file" defaultValue="" />
                          </div>
                      }
                      else if (ques.optionType == "textarea"){
                        return <div className="space-y-1">
                          <Label>{ques.order+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <Textarea />
                          </div>
                      }
                    })
                  }
                  {/* <div className="space-y-1">
                    <Label>Untitled Item</Label>
                    <Input placeholder="aaaa" type="file" required />
                  </div>
                  <div className="space-y-1">
                    <Label>Card holder </Label>
                    <Input defaultValue="JOHN DOE" />
                  </div>
                  <div className="flex-1 space-y-1">
                    <Label>Expiration date </Label>
                    <Input type="date" defaultValue="MM/YY" />
                  </div>
                  
                  <div className="space-y-1">
                    <Label>Messager for author </Label>
                    <Textarea placeholder="..." />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div> */}
                </Tab.Panel>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <Label>Email </Label>
                    <Input type="email" defaultValue="example@gmail.com" />
                  </div>
                  <div className="space-y-1">
                    <Label>Password </Label>
                    <Input type="password" defaultValue="***" />
                  </div>
                  <div className="space-y-1">
                    <Label>Messager for author </Label>
                    <Textarea placeholder="..." />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
            <div className="pt-5">
              <ButtonSecondary href={"/pay-done"}>Apply Coupon</ButtonSecondary>
            </div>
            <div className="pt-8">
              <ButtonPrimary href={"/pay-done"}>Confirm and pay</ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CheckOutPagePageMain;
