import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter, Routes, Route, useNavigate, Navigate, Outlet  } from "react-router-dom";
import { AuthContext } from "contexts/AuthContext";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import OrganizerPage from "containers/OrganizerPage/OrganizerPage";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
import ListingCarPage from "containers/ListingCarPage/ListingCarPage";
import ListingCarMapPage from "containers/ListingCarPage/ListingCarMapPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountOrders from "containers/AccountPage/AccountOrders";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";

import OrderConfirmationPage from "containers/OrderConfirmationPage/OrderConfirmationPage";

import EventTicketPage from "containers/EventTicketPage/EventTicketPage";

import PageAddListing1 from "containers/PageAddListing1/PageAddListing1";
import PageAddListing2 from "containers/PageAddListing1/PageAddListing2";
import PageAddListing3 from "containers/PageAddListing1/PageAddListing3";
import PageAddListing4 from "containers/PageAddListing1/PageAddListing4";
import PageAddListing5 from "containers/PageAddListing1/PageAddListing5";
import PageAddListing6 from "containers/PageAddListing1/PageAddListing6";
import PageAddListing7 from "containers/PageAddListing1/PageAddListing7";
import PageAddListing8 from "containers/PageAddListing1/PageAddListing8";
import PageAddListing9 from "containers/PageAddListing1/PageAddListing9";
import PageAddListing10 from "containers/PageAddListing1/PageAddListing10";

import PageCreateEvent01 from "containers/PageCreateEvent/PageCreateEvent01";
import PageCreateEvent02 from "containers/PageCreateEvent/PageCreateEvent02";
import PageCreateEvent03 from "containers/PageCreateEvent/PageCreateEvent03";
import PageCreateEvent04 from "containers/PageCreateEvent/PageCreateEvent04";
import PageCreateEvent05 from "containers/PageCreateEvent/PageCreateEvent05";
import PageCreateEventPreview from "containers/PageCreateEvent/PageCreateEventPreview";

import PageHome2 from "containers/PageHome/PageHome2";
import ListingRealEstateMapPage from "containers/ListingRealEstatePage/ListingRealEstateMapPage";
import ListingRealEstatePage from "containers/ListingRealEstatePage/ListingRealEstatePage";
import SiteHeader from "containers/SiteHeader";
import ListingFlightsPage from "containers/ListingFlightsPage/ListingFlightsPage";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import PageHome3 from "containers/PageHome/PageHome3";
import ListingStayDetailPage from "containers/ListingDetailPage/listing-stay-detail/ListingStayDetailPage";
import ListingCarDetailPage from "containers/ListingDetailPage/listing-car-detail/ListingCarDetailPage";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/listing-experiences-detail/ListingExperiencesDetailPage";
import OrganizerDashboard from "containers/AccountOrganizerPage/OrganizerDashboard";
import OrganizerEvents from "containers/AccountOrganizerPage/OrganizerEvents";
import OrganizerSettings from "containers/AccountOrganizerPage/OrganizerSettings";
import EventDashboard from "containers/AccountEventManagement/EventDashboard";
import EventOrders from "containers/AccountEventManagement/EventOrders";
import EventTickets from "containers/AccountEventManagement/EventTickets";
import EventCustomers from "containers/AccountEventManagement/EventCustomers";
import { auth,fs } from "firebaseSetup";
import { getDoc, doc} from "firebase/firestore/lite"
import AccountLogout from "containers/AccountPage/AccountLogout";
import AccountOrganization from "containers/AccountPage/AccountOrganization";

import EventDetailPage from "containers/EventDetailPage/EventDetailPage";
import EventMarketing from "containers/AccountEventManagement/EventMarketing";



export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/e/:id", component: EventDetailPage },

  { path: "/checkout", component: CheckOutPage },
  { path: "/event-order/:id/:viewId", component: CheckOutPage },
  { path: "/pay-done", component: PayPage },
  { path: "/order-confirmation/:id", component: OrderConfirmationPage },
  { path: "/ticket-id/:id", component: EventTicketPage},
  //
  { path: "/author", component: AuthorPage },
  { path: "/account", protecteed: true, component: AccountPage },
  { path: "/account-password", protecteed: true, component: AccountPass },
  { path: "/account-orders", protecteed: true, component: AccountOrders },
  { path: "/account-billing", protecteed: true, component: AccountBilling },
  { path: "/account-logout", protecteed: true, component: AccountLogout},
  { path: "/account-organization", protecteed: true, component: AccountOrganization},
  //
  { path: "/blog", component: BlogPage },
  { path: "/news", component: BlogPage},
  { path: "/help-center", component: BlogPage},
  { path: "/sponsorship", component: BlogPage},
  { path: "/blog-single", component: BlogSingle },
  //
  //
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin},
  { path: "/subscription", component: PageSubcription },

  //organizer page
  { path: "/organizer/:id", component: OrganizerPage}, 

  { path: "/dashboard", protecteed: true, component: OrganizerDashboard },
  { path: "/dashboard-events", protecteed: true, component: OrganizerEvents},
  { path: "/dashboard-users", protecteed: true, component:  OrganizerDashboard},
  { path: "/dashboard-ticket-list", protecteed: true, component:  OrganizerDashboard},
  { path: "/dashboard-org-settings", protecteed: true, component:  OrganizerSettings},
  { path: "/dashboard-org-landing-page", protecteed: true, component:  OrganizerDashboard},

  { path: "/manage-event/:id", protecteed: true, component:  EventDashboard},
  { path: "/manage-event-order/:id", protecteed: true, component:  EventOrders},
  { path: "/manage-event-customer/:id", protecteed: true, component:  EventCustomers },
  { path: "/manage-event-ticket/:id", protecteed: true, component:  EventTickets },
  { path: "/manage-event-marketing/:id", protecteed: true, component: EventMarketing},
  { path: "/manage-event-setting/:id", protecteed: true, component:  EventDashboard },

  { path: "/modify-event/:id", protecteed: true, component: EventDetailPage},

  { path: "/create-event-01", protecteed: true, component: PageCreateEvent01},
  { path: "/create-event-02", protecteed: true, component: PageCreateEvent02},
  { path: "/create-event-03", protecteed: true, component: PageCreateEvent03},
  { path: "/create-event-04", protecteed: true, component: PageCreateEvent04},
  { path: "/create-event-05", protecteed: true, component: PageCreateEvent05},
  { path: "/create-event-preview", protecteed: true, component: PageCreateEventPreview},

  //
];

const PrivateRoute = () => {
  const { currentUser } = useContext(AuthContext);
  return currentUser ? <Outlet /> : <Navigate to="/" replace />;
}

const PublicRoute = () => {
  const { currentUser } = useContext(AuthContext);
  return !currentUser ? <Outlet /> : <Navigate to="/" replace />;
}

const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  const { currentUser } = useContext(AuthContext)

  return (
    <BrowserRouter>
      <ScrollToTop />
      <SiteHeader />
      <Routes>
      {pages.map(({ component, path, protecteed }) => {
          const Component = component;
          if (protecteed != null && protecteed && !currentUser){
            return <Route key={path} element={<PageLogin />} path={path} />;
          }
          else{
            return <Route key={path} element={<Component />} path={path} />;
          }
          
        })}
        <Route element={<Page404 />} />
      </Routes>

      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
