import Label from "./../../components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import Avatar from "./../../shared/Avatar/Avatar";
import ButtonPrimary from "./../../shared/Button/ButtonPrimary";
import Input from "./../../shared/Input/Input";
import Select from "./../../shared/Select/Select";
import Textarea from "./../../shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";
import Grid from '@mui/material/Grid2';
import { DataGrid } from "@mui/x-data-grid";
import { auth, fs} from 'firebaseSetup'
import { Box, Paper, Button, IconButton, Typography, useTheme } from "@mui/material";
import useEventDashboardLoader from "hooks/useEventDashboardLoader";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Link } from "react-router-dom";

export interface EventManagementPageProps {
  className?: string;
  eventId?: string;
  eventTitle?: string;
}

const EventDashboard: FC<EventManagementPageProps> = ({ className = "", eventId = "", eventTitle = "Event Title Here" }) => {
    
    
    const {id} = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [dataLoad, setDataLoad] = useState("Loading")
    
    const eventDashboard = useEventDashboardLoader({ eventId: id || "", isLoading: false, mode: 'tickets', handleCompleteCallback: ()=>{
      
    }})

   

  

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event Organizer Dashboard</title>
      </Helmet>
      <CommonLayout eventId={id || ""} eventTitle={eventDashboard.eventItem.title}>
      <div className="mb-5 space-y-6 sm:space-y-8">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-semibold">{'About Event'}</h2>
          <ButtonSecondary href={"/modify-event/"+(id || "")}>Modify Event</ButtonSecondary>
        </div>
        
        <p>Event Description</p>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      </div>

        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-2xl font-semibold">{'Dashboard'}</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
    <Grid key={'A'} size={{ xs: 2, sm: 4, md: 4 }}>
      <Paper 
        elevation={3} 
        style={{padding: '25px'}}>
        <p>{'Ticket Sold'}</p>
        <h2 style={{fontSize: 25, fontWeight: 'bold', marginBottom: 5, marginTop: 5}}>$20,000</h2>
        <p>{'20 Sold'}</p>
        <br/>
        <p>{'just now'}</p>
      </Paper>
    </Grid>
    <Grid key={'A'} size={{ xs: 2, sm: 4, md: 4 }}>
      <Paper 
        elevation={3} 
        style={{padding: '25px'}}>
        <p>{'Page Views'}</p>
        <h2 style={{fontSize: 25, fontWeight: 'bold', marginBottom: 5, marginTop: 5}}>200K</h2>
        <p>{'20 from Event Go'}</p>
        <br/>
        <p>{'just now'}</p>
      </Paper>
    </Grid>
</Grid>

<div className="space-y-6 sm:space-y-8">

          <h2 className="text-2xl font-semibold">Share</h2>
          <h2 className="text-1xl font-normal">Event Link</h2>
          <Link to={"/e/"+id}><p>{'https://evtgo.com/e/'+id}</p></Link>

  </div>

  <div className="mt-5 space-y-6 sm:space-y-8">

<h2 className="text-2xl font-semibold">Sales by ticket types</h2>
  <DataGrid 
      checkboxSelection 
        sx={{ overflowX: 'scroll' }}
        rows={[
          {
            id: 1,
            ticketType: 'General Admission',
            price: 'CA$20.00',
            sold: '100/500'
          }
        ]} 
        columns={[
          { field: "ticketType", flex: 2, headerName: "Ticket Type" },
          { field: "price", flex: 1, headerName: "Price" },
          { field: "sold", flex: 1, headerName: "Sold" },
        ]} />
</div>
          
          <div className="space-y-6 sm:space-y-8">

          <h2 className="text-2xl font-semibold">Recent Orders</h2>
            <DataGrid 
                checkboxSelection 
                  sx={{ overflowX: 'scroll' }}
                  rows={[
                    {
                      id: 1,
                      orderId: '#000000',
                      name: 'Sam Chen',
                      quantity: 1,
                      price: 'CA$20.00',
                      timestamp: '2024-11-11'
                    }
                  ]} 
                  columns={[
                    { field: "orderId", flex: 1, headerName: "Order Number" },
                    { field: "name", flex: 1, headerName: "Name" },
                    { field: "quantity", flex: 1, headerName: "Quantity" },
                    { field: "price", flex: 1, headerName: "Price" },
                    { field: "timestamp", flex: 1, headerName: "Date" },
                  ]} />
          </div>

          <div className="space-y-6 sm:space-y-8">

          <h2 className="text-2xl font-semibold">Other Actions</h2>
            
          </div>

         
          
        </div>
      </CommonLayout>
    </div>
  );
};

export default EventDashboard;
